<template>
  <v-card flat class="warning">
    <v-card-title class="white--text text-body-1 font-weight-bold mb-2">
      {{ $t("checkout.completeProfileTitle") }}
    </v-card-title>

    <v-card-subtitle class="white--text text-body-2">
      {{ $t("checkout.completeProfileSubTitle") }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        outlined
        text
        class="white default--text"
        to="/profile/profile-update"
        block
      >
        {{ $t("checkout.completeProfileTitle") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.card-complete-profile {
  margin-bottom: 15px;
}
</style>
<script>
export default {
  name: "CheckoutCompleteProfile"
};
</script>

<style lang="scss">
.card-complete-profile {
  background: #ff8484 0% 0% no-repeat padding-box;
  color: white;
  .v-card__title {
    font-size: 20px;
    font-weight: bold;
  }
  .v-card__subtitle {
    color: white;
    font-size: 16px;
  }
  .complete-profile-btn {
    background: white;
    color: #ff8484;
    border: 2px solid #ff8484;
    width: 100%;
  }
}
</style>
