<template>
  <v-card flat class="card-complete-profile warning">
    <!-- <v-card-title>
      {{ $t("checkout.confirmEmailTitle") }}
    </v-card-title> -->

    <v-card-subtitle class="text-body-1 white--text">
      <i18n path="checkout.confirmEmailSubTitle" tag="div">
        <template v-slot:link>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                class="white--text text-decoration-underline"
                @click="sendActivationMail"
              >
                <span v-bind="attrs" v-on="on">
                  {{ $t("checkout.confirmEmailLink") }}</span
                >
              </a>
            </template>
            <span>{{ $t("checkout.confirmEmailTooltip") }}</span>
          </v-tooltip>
        </template>
      </i18n>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CheckoutConfirmEmail",
  data() {
    return {
      polling: null
    };
  },
  methods: {
    ...mapActions({ reload: "cart/loadCart" }),
    pollData() {
      this.polling = setInterval(() => {
        this.reload();
        this.$emit("loadCart");
      }, 10000);
    },
    sendActivationMail() {
      this.$emit("sendEmail");
    }
  },
  created() {
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  }
};
</script>

<style lang="scss">
.card-complete-profile {
  background: #f2dede 0% 0% no-repeat padding-box;
  .v-card__subtitle {
    font-size: 14px;
    font-weight: bold;
    color: #ff8484;
  }
  .complete-profile-btn {
    background: white;
    color: #ff8484;
    border: 2px solid #ff8484;
    width: 100%;
  }
}
</style>
